<template>
  <div class="login">
    <div class="login__form">
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <div class="login__form-logo">
              <img :src="getImage('eden_logo.svg')" alt="Eden" />
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Email" prop="email" :rules="validateEmail()">
              <el-input
                type="text"
                v-model="form.email"
                @keyup.enter.native="login"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Password"
              prop="password"
              :rules="validateField()"
            >
              <el-input
                type="password"
                show-password
                v-model="form.password"
                @keyup.enter.native="login"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="is-flex justify-center">
          <el-button :loading="submitting" type="primary" @click="login"
            >Login</el-button
          >
        </div>
        <div class="password-reset is-flex justify-center align-center">
          <span class="font-sm text-grey-tertiary"
            >Forgot password?
            <span class="text-primary link" @click="forgotPassword">
              Reset now</span
            ></span
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as actions from "../store/action-types";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      submitting: false,
    };
  },
  methods: {
    login() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.form.email = this.form.email.toLowerCase();

        const payload = {
          email: this.form.email.toLowerCase().trim(),
          password: this.form.password,
        };

        this.submitting = true;
        this.$store
          .dispatch(actions.LOGIN, payload)
          .then((response) => {
            if (response.data.status) {
              this.mixpanelTrack({ event: "btn_login" });
              const role = response.data.data.role;
              if (this.$route.query.redirect) {
                window.location.href = this.$route.query.redirect;
                return;
              }

              if (role === "steward") {
                this.$router.push({ name: "production-provisions.index" });
              } else if (role === "ofa") {
                this.$router.push({ name: "order-fulfillment.index" });
              } else if (role === "vendor_manager") {
                this.$router.push({ name: "vendors.index" });
              } else {
                this.$router.push({ name: "customers.index" });
              }
              this.submitting = false;
            } else {
              this.submitting = false;
              this.$message.error(response.data.message);
            }
          })
          .catch(() => {
            this.submitting = false;
            this.$message.error(
              "Unauthorized Access. Invalid email / password / access disabled",
            );
          });
        return true;
      });
    },
    forgotPassword() {
      this.$router.push({ name: "forgot-password" });
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &__form {
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-logo {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    .el-form {
      width: 100%;
    }

    .password-reset {
      margin-top: 20px;
    }
    .link {
      cursor: pointer;
    }
  }
}
</style>
